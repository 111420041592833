import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {} from 'gatsby-plugin-transition-link';

export default function Link(props){
  const { children, link, ...other } = props;

  return (
    <AniLink 
      paintDrip
      /*cover*/ 
      hex="#bd7e04"
      duration={0.6}
      key={link} 
      to={link} 
      role="button" 
      bg="
        url(/lesecuriesduchalet.gif)
        center / 60%   /* position / size */
        no-repeat        /* repeat */
        fixed            /* attachment */
        padding-box      /* origin */
        content-box      /* clip */
        #e5732b            /* color */
      "
      {...other}>
      {children}
    </AniLink>
  )
}