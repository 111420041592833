import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from "./footer.module.scss";
import { Container } from "react-bootstrap";
import { FaFacebookSquare, FaInstagram, FaYoutube, FaMapMarkerAlt } from "react-icons/fa";
import { MdPhonelinkRing } from "react-icons/md";
import useSiteMetadata from "../../../utils/useSiteMetadata";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(sourceInstanceName: { eq: "images" }, name: { eq: "footer-background" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            src
          }
        }
      }
      logo: file(sourceInstanceName: { eq: "images" }, name: { eq: "logo-white" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const metadata = useSiteMetadata();

  return (
    <footer className={styles.footer} style={{ backgroundImage: `url(${data.background.childImageSharp.fluid.src})` }}>
      <Container>
        <div className={styles.footerContent}>
          {/*<Img style={{maxWidth: "300px"}} fluid={data.logo.childImageSharp.fluid} alt="Les écuries du Chalet"/>*/}
          <div>
            <h2 className={styles.footerBrand}>{metadata.title}</h2>
            <div>Centre équestre à Annecy en Haute-Savoie</div>
          </div>
          <div>
            <a
              href="https://www.facebook.com/lesecuriesduchalet"
              target="_blank"
              rel="noreferrer"
              className={styles.footerIconsLink}
            >
              <FaFacebookSquare className={styles.footerIcons} />
            </a>

            <a
              href="https://www.instagram.com/les_ecuries_du_chalet"
              target="_blank"
              rel="noreferrer"
              className={styles.footerIconsLink}
            >
              <FaInstagram className={styles.footerIcons} />
            </a>

            {/*<a href="https://www.youtube/alandupin" target="_blank" rel="noreferrer" className={styles.footerIconsLink}>
                    <FaYoutube className={styles.footerIcons}/>
                </a>*/}
          </div>
          <div className={styles.footerLegal}>
            <span>Copyright Les Ecuries du Chalet 2020</span>
            <Link to="/privacy">Politique de confidentialité</Link>
            <Link to="/termes-du-service">Termes du service</Link>
          </div>
          <div>
            <div className={styles.footerInfo}>
              <FaMapMarkerAlt />{" "}
              <span>
                <a target="_blank" href={metadata.googleMap}>
                  {metadata.address}
                </a>
              </span>
            </div>
            <div className={styles.footerInfo + " mt-1"}>
              <MdPhonelinkRing />{" "}
              <span>
                <a href="tel:+33620526418">{metadata.phone}</a>
              </span>
            </div>
          </div>
          <span style={{height: "1px", overflow:"hidden"}}>
            Centre équestre les écuries du Cha'let (chalet) à Annecy Epagny en Haute Savoie, très prêt de genève.
            Balade, cheval, poney, manège, carrière olympique 20x40 m, la meilleure des installations possible. 
            Cours de chevaux pas cher, cours de poney pas cher.
          </span>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
