import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import * as styles from "./header.module.scss";
import Link from "../link";
import brandGif from "../../../gifs/lesecuries-white.gif";
import brandGifColored from "../../../gifs/lesecuries-gold.gif";
import { isBrowser } from "../../../services/helper";
import SliderGlide from "../slider-glide";
import { graphql, useStaticQuery } from "gatsby";



const Header = (props) => {
  const [scrolled, setScrolled] = useState(false);
  const [windowSize, setWindowSize] = useState({ height: 0, width: 0 });
  const [gifSrc, setGifSrc] = useState(null);

  const data = useStaticQuery(graphql`
  query HeaderQuery {
    file(sourceInstanceName: { eq: "images" }, name: { eq: "logo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    site {
      siteMetadata {
        navbarLinks {
          link
          text
        }
      }
    }
    allContentfulPage(filter: {contentful_id: {ne: "4beDDLbNgyJhLzEeqjDR1M"}}) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
    contentfulMessages(name: { eq: "header" }) {
      messages {
        messages
      }
    }
  }
`);
  const messagesHeader = data.contentfulMessages?.messages?.messages?.split("\n");

  const getMenus = () => {
    let navLinks = data.site.siteMetadata.navbarLinks;
    let menus = navLinks.slice(0, navLinks.length - 1).concat(navLinks[navLinks.length - 1]);

    let pages = data.allContentfulPage;
    if (pages && pages.edges)
      menus = menus.concat(
        pages.edges.map((p) => ({
          link: p.node.slug,
          text: p.node.title,
        }))
      );

    return menus.map((link, index) => {
      return (
        <Link
          key={index}
          link={`/${link.link}`}
          className={`${styles.navItem} ${props.currentPage === link.link.substr(1) ? " active" : ""}`}
        >
          {link.text}
        </Link>
      );
    });
  };

  useEffect(() => {
    if (isBrowser()) {
      updateWindowDimensions();
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateWindowDimensions);
    }
    setGifSrc(props.color === "white" ? brandGif : brandGifColored);

    setTimeout(() => {
      var element = document.querySelector(".tl-edges .tl-wrapper");
      element.style.transform = "none";
    }, 1000);

    return () => {
      if (isBrowser()) {
        window.addEventListener("resize", updateWindowDimensions);
        window.removeEventListener("scroll", handleScroll);
      }
      setGifSrc(null);
    };
  }, []);

  const updateWindowDimensions = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  const handleScroll = (event) => {
    if (isBrowser()) {
      setScrolled(window.pageYOffset > 0);
    }
  };

  return (
    <header color={props.color}>
      <div className={styles.headerMessages}>
        {props.messageReservation && (
          <div className={styles.messageReservation}>
            Contact et réservations au <a href="tel:+33620526418">06.20.52.64.18</a>
          </div>
        )}
        {messagesHeader && messagesHeader.length > 0 && (
          <div className={styles.messages} style={{ top: props.messageReservation ? "30px" : 0 }}>
            <SliderGlide
              options={{
                type: "carousel",
                perView: 1,
                autoplay: 3000,
                animationDuration: 1000,
                focusAt: "center",
                gap: 0,
                breakpoints: {},
              }}
            >
              {messagesHeader.map((msg, index) => {
                return (
                  <div
                    style={{
                      margin: "auto",
                      fontSize: index == 0 ? "larger" : "normal",
                      fontWeight: index == 0 ? "bold" : "normal",
                    }}
                  >
                    {msg}
                  </div>
                );
              })}
            </SliderGlide>
          </div>
        )}
      </div>

      {(scrolled || windowSize.width < 767) && (
        <Navbar
          style={{
            top: `${(messagesHeader && messagesHeader.length > 0 ? 32 : 0) + (props.messageReservation ? 30 : 0)}px`,
          }}
          className={`${styles.navbar} ${scrolled ? styles.navbarSticky : ""}`}
          fixed="top"
          bg="light"
          expand="md"
        >
          <Navbar.Brand>
            <Link link="/">
              <GatsbyImage
                className={styles.navbarBrand}
                image={data.file.childImageSharp.gatsbyImageData}
                alt="Les écuries du Chalet"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="toggle-navbar-nav" />
          <Navbar.Collapse id="toggle-navbar-nav">
            <Nav className={styles.navbarNav}>{getMenus()}</Nav>
          </Navbar.Collapse>
        </Navbar>
      )}

      <Container>
        <Row>
          <div
            style={{
              marginTop:
                windowSize.width < 767
                  ? `${
                      100 + (messagesHeader && messagesHeader.length > 0 ? 20 : 0) + (props.messageReservation ? 30 : 0)
                    }px`
                  : messagesHeader && messagesHeader.length > 0
                  ? "20px"
                  : 0,
            }}
            className="col-md-8 offset-md-2 col-xs-12"
          >
            <Link link="/">
              <img className={styles.headerBrandGif} src={gifSrc} alt={"Les écuries du Chalet"} />
            </Link>
          </div>
        </Row>
        {windowSize.width > 767 && (
          <Row className="justify-content-center">
            <Nav className={`${styles.navbarDesktop} ${styles.navbarNav}`}>{getMenus()}</Nav>
          </Row>
        )}
      </Container>
    </header>
  );
};

export default Header;