/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/header"
import Footer from "./Footer/footer"

const Layout = ({ messageReservation, headerAbsolute, currentPage, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {
        headerAbsolute ? (
          <div style={{position: "absolute", width: "100%"}}>
            <Header currentPage={currentPage} messageReservation={messageReservation} color="white" />
          </div>
        ) : (
          <Header currentPage={currentPage} messageReservation={messageReservation} />
        )
      }
      
      <main style={{marginTop: headerAbsolute ? '' : 'var(--padding-xs)'}}>
        {children}
      </main>
      
      <Footer />
    </>
  )
}

Layout.default = {
  messageReservation: false,
  headerAbsolute: false
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  messageReservation: PropTypes.bool,
  headerAbsolute: PropTypes.bool,
  currentPage: PropTypes.string,
}

export default Layout
