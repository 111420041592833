import React, { Component } from "react";
import Glide from "@glidejs/glide";
import PropTypes from "prop-types";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

import "../../../node_modules/@glidejs/glide/dist/css/glide.core.css";
import "../../../node_modules/@glidejs/glide/dist/css/glide.theme.css";

class SliderGlide extends Component {
  slider = null;
  defaultOptions = {
    type: "slider",
    startAt: 0,
    perView: 5,
    hoverpause: false,
    rewind: true,

    showControls: false,

    gap: 10, //space between
    bound: true,

    autoplay: 2000,
    perTouch: 3,
    animationDuration: 2000,
    rewindDuration: 2000,
    animationTimingFunc: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",

    breakpoints: {
      700: {
        perView: 2,
        perTouch: 2,
      },
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount = () => {
    // Generated random id
    this.setState({ id: `glide-${Math.ceil(Math.random() * 100)}` }, this.initializeGlider);
  };

  initializeGlider = () => {
    let options = {
      ...this.defaultOptions,
      ...this.props.options,
    };
    this.slider = new Glide(`#${this.state.id}`, options);
    this.slider.mount();
    setTimeout(() => {
      this.slider.update();
    }, 500);
  };

  componentWillReceiveProps = (newProps) => {
    if (this.props.options.startAt !== newProps.options.startAt) {
      this.slider.go(`=${newProps.options.startAt}`);
    }
  };

  render = () => {
    const { children, className, ...other } = this.props;
    return (
      <div
        className={`glide ${className ?? ""}`}
        id={this.state.id}
        style={{ overflowX: "hidden", userSelect: "none", maxWidth: "100vw" }}
        {...other}
      >
        <div className="glide__track" data-glide-el="track">
          <div className="glide__slides" style={{ display: "flex" }}>
            {children.map((slide, index) => {
              return (
                slide &&
                React.cloneElement(slide, {
                  key: index,
                  className: `${slide.props.className ?? ""} glide__slide`,
                })
              );
            })}
          </div>
        </div>
        {this.props.options?.showControls && (
          <>
            <div data-glide-el="controls">
              <button className="glide__arrow glide__arrow--prev" data-glide-dir="<" style={{ left: "1rem" }}>
                <FaArrowAltCircleLeft />
              </button>
              <button className="glide__arrow glide__arrow--next" data-glide-dir=">" style={{ right: "1rem" }}>
                <FaArrowAltCircleRight />
              </button>
            </div>
            <div className="glide__bullets" data-glide-el="controls[nav]">
              {children.map((slide, index) => (
                <button class="glide__bullet" data-glide-dir={`=${index}`}></button>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };
}

SliderGlide.defaultProps = {
  options: {},
};

SliderGlide.inputProps = {
  options: PropTypes.object,
  className: PropTypes.string,
};

export default SliderGlide;
