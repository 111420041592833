// extracted by mini-css-extract-plugin
export var navbar = "header-module--navbar--uJgMt";
export var navbarBrand = "header-module--navbar-brand--ZbqH0";
export var navbarNav = "header-module--navbar-nav--6+X9v";
export var navItem = "header-module--nav-item--c9nyf";
export var navbarSticky = "header-module--navbar-sticky--j4LK1";
export var navbarDesktop = "header-module--navbarDesktop--AEj5G";
export var headerBrandGif = "header-module--header-brand-gif--iZH1d";
export var headerMessages = "header-module--headerMessages--yatvU";
export var messageReservation = "header-module--messageReservation--Bz5DT";
export var messages = "header-module--messages--7-zbf";